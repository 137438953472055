
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiCampaign } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-marketing-campaigns-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addMarketingCampaignsModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();
    const formData = ref({
      name: "",
      status: "",
      purpose: "",
      parent_id: "",
      type: "",
      start_date: null,
      end_date: null,
      description: "",
      // selection_mode: 0, // 自动:10|手动:20|混合:30
      // mb_segmentation: [],
      // coverage_scope: [],
      // dedicated_group_level: [],
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      purpose: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      end_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const campaignPurposeOptions = ref([]);
    const campaignTypeOptions = ref([]);
    const dedicatedGroupLevelOptions = ref([]);
    const dedicatedGroupLevelLoading = ref<boolean>(false);
    const campaignOptions = ref([]);
    const campaignLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "distribution_campaign_purpose",
          "distribution_campaign_type",
        ],
      });
      if (data.code == 0) {
        campaignPurposeOptions.value =
          data.data.distribution_campaign_purpose.items;
        campaignTypeOptions.value = data.data.distribution_campaign_type.items;
      }
    };

    const getCampaignsSourceData = async () => {
      campaignLoading.value = true;
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      campaignLoading.value = false;
      if (data.code == 0) {
        campaignOptions.value = data.data;
      }
    };

    const getInfluencerGroupSourceData = async () => {
      dedicatedGroupLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      dedicatedGroupLevelLoading.value = false;
      if (data.code == 0) {
        dedicatedGroupLevelOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiCampaign.addCampaign(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addMarketingCampaignsModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addMarketingCampaignsModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getDropdownOptions();
      getCampaignsSourceData();
      getInfluencerGroupSourceData();
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      addMarketingCampaignsModalRef,
      submitButton,
      campaignPurposeOptions,
      campaignTypeOptions,
      dedicatedGroupLevelOptions,
      dedicatedGroupLevelLoading,
      campaignOptions,
      campaignLoading,
      t,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
